import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

const Wrapper = styled.article`
    ${tw`w-full lg:w-1/2 p-3 md:p-6`}
`

const Container = styled.div`
    ${tw`relative content-end h-full rounded-lg overflow-hidden border border-solid bg-psb-dark-light-gray-400 border-psb-dark-gray-400 shadow-lg`}
`

const Title = styled.h3`
    ${tw`px-4 pb-12 pt-2`}
`

const DateRow = styled.div`
    ${tw`absolute w-full bottom-0`}
`

const Divider = styled.hr`
    ${tw`p-0 border-psb-dark-gray-400`}
`

const Meta = styled.div`
    ${tw`text-psb-orange-400 text-sm md:text-base font-semibold p-2`}
`

const Overlay = styled.div`
     ${tw`z-10 absolute m-3 px-4 py-0 text-base md:text-lg lg:text-xl rounded-md font-bold bg-psb-blue-400 text-white`}
     right: 0;
 `

const ArticleCard = ({ title, date, categories, tags, featuredImage, slug }) => {
    return(
        <Wrapper>
            <Container>
                <Link to={slug}>
                    <Overlay>
                        {categories.map((category, index, array) => {
                            return (
                                <>
                                    {category.toUpperCase()}{ index < array.length - 1 ? ", " : "" }
                                </>
                            )
                        })}
                    </Overlay>
                    <Img fluid={featuredImage} />
                    <Title>{title}</Title>
                    <DateRow>
                        <Divider />
                        <Meta>
                            {date}
                        </Meta>
                    </DateRow>
                </Link>
            </Container>
        </Wrapper>
    )
}

export default ArticleCard